import React, { useCallback, useState } from 'react';
import { Grid, BottomNavigation, BottomNavigationAction, Button, Typography, List, ListItem, ListItemText, IconButton, ListItemAvatar, CircularProgress } from '@material-ui/core';
import { LocalGasStation, LocalParking, Chat, AddAPhoto, Send, Close, InsertComment } from '@material-ui/icons';
import { useDrawer } from '../hooks';
import { useDispatch, useValue } from '../data';
import { TextField } from '../rf/input/Text';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { ToggleField } from '../rf/input/Toggle';
import { KMOraInput, KartyaInput, OffsetInput } from './Input';
import { NativeSelect } from '../rf/input/Select';
import { SwitchField } from '../rf/input/Switch';
import { error } from '../log';
import moment from 'moment';
import { asc, stampFromMoment } from '../../utils.mjs';
import MUINativeSelect from '@material-ui/core/NativeSelect';
import imageCompression from 'browser-image-compression';
import config from '../config.json'

let tankoptions = [
  { value: 0, label: 'Tele' }, { value: 1, label: 'Nem tele' }, { value: 2, label: 'Hűtő' }, { value: 3, label: 'AdBlue' },
]
if (config && config.nincshuto) {
  tankoptions = [
    { value: 0, label: 'Tele' }, { value: 1, label: 'Nem tele' }, { value: 3, label: 'AdBlue' },
  ]
}

function PihenoField() {
  return <ToggleField name="piheno" size="large" exclusive options={[
    { value: 9, label: '9H' }, { value: 11, label: '11H' }, { value: 24, label: '24H' }, { value: 45, label: '45H' },
  ]}
  />
}

function TankField() {
  return <ToggleField name="tank" exclusive options={tankoptions}
  />
}

function HutoField() {
  let { values } = useFormikContext();
  if (values.tank === 2) {
    return <>
      <ToggleField name="hutotele" exclusive options={[
        { value: 1, label: 'Hűtő tele' },
        { value: 0, label: 'Nem tele' }
      ]} />
      <TextField label="Hűtő óraállás" name="huto" />
    </>
  } else {
    return null;
  }
}

function UploadTipus() {
  return <ToggleField name="tipus" exclusive options={[
    { value: 1, label: 'CMR' }, { value: 2, label: 'Menetlevél' }, { value: 3, label: 'Plomba' }, { value: 4, label: 'Egyéb' }
  ]}
  />
}

export function JaratField() {
  let jarat = useValue('jarat');
  if (jarat) {

    jarat = jarat.filter(j => j.sajat && !j.lemondva).map(j => {
      let { viszonylat, fuvar } = j;
      if (fuvar && fuvar.mref) viszonylat = fuvar.mref;
      return { value: j.pos, label: `${j.pos} - ${viszonylat}` }
    });
  } else {
    jarat = [];
  }
  return <Field fullWidth component={NativeSelect} name="jarat" label="Járat" options={jarat} />
}

export function SablonField(props) {
  let slist = useValue('sablon');
  if (!slist) return null;

  return <Field name="sablon" type="text">{({ field, form: { values, setFieldValue } }) => {
    const { sablon } = values;
    if (!sablon) return null;
    let ph = [], i;
    let { text } = sablon;
    let sc = [];
    for (i = 0; i < text.length; i++) if (text[i] === "$") ph.push(i);
    if (!ph.length) sc.push(<div key={0}>{text}</div>);
    let st = 0;
    for (i = 0; i < ph.length; i++) {
      let opt = text[ph[i] + 1];
      opt = `opt${opt}`;
      let txt = text.substring(st, ph[i] - 1);
      st = ph[i] + 2;
      sc.push(<span key={i} style={{ margin: 4, fontSize: 16 }} >{txt}</span>);
      sc.push(<MUINativeSelect key={"select" + i}
        value={values[opt]}
        onChange={(ev) => setFieldValue(opt, ev.target.value)}
      >
        {sablon[opt].map((o, i) => <option key={i} value={i}>{o}</option>)}
      </MUINativeSelect>);
    }
    let vege = text.substring(st);
    if (vege) sc.push(<span key="vege" style={{ margin: 4, fontSize: 16 }} >{vege}</span>);

    sc.push(<IconButton key="close" onClick={() => setFieldValue('sablon', false)}><Close /></IconButton>);

    return <div>{sc}</div>;
  }}</Field>
}

export function SablonSelector(props) {
  let slist = useValue('sablon');
  if (!slist) return null;

  return <Field name="sablon" type="text">{({ field, form: { values, setFieldValue } }) => {
    const { sablon, txt } = values;
    if (sablon) return null;
    let list = slist;
    if (!sablon && txt) {
      console.log('filter sablon:', txt);
      list = slist.filter(s => s.text.toLowerCase().includes(txt.toLowerCase().trim()));
    }
    return <List dense style={{ maxHeight: 140, overflowY: 'scroll' }}>
      {list.map(s => <ListItem onClick={() => {
        setFieldValue('sablon', s);
        setFieldValue('opt1', 0);
        setFieldValue('opt2', 0);
        setFieldValue('txt', '');
      }} key={s.rnev} style={{ overflowX: 'hidden' }}><ListItemAvatar><InsertComment /></ListItemAvatar><ListItemText primary={s.text.replace('$1', '...').replace('$2', '...')} /></ListItem>)}
    </List>
  }}</Field>
}


export function UploadField() {
  const [preview, setPreview] = useState();
  const [compressing, setCompressing] = useState(false);
  const [, , { setValue }] = useField('file');

  const onFileUpload = useCallback(async (event) => {
    if (event.target.files[0]) {
      try {
        console.log("compressing:", event.target.files[0]);
        setValue('compressing');
        setCompressing(true);
        const { name, type, lastModified } = event.target.files[0];
        const cf = await imageCompression(event.target.files[0], { maxSizeMB: 0.2 });
        setCompressing(false);
        setPreview(URL.createObjectURL(cf));
        setValue(new File([cf], name, { type, lastModified }));
      } catch (e) {
        error("tömörítési hiba", { error: e.stack });
      }
    }
  }, [setValue, setPreview, setCompressing]);
  return <div>
    <label htmlFor="file">
      <input style={{ display: 'none' }}
        id="file"
        name="file"
        accept="image/x-png,image/jpeg,image/gif"
        type="file"
        onChange={onFileUpload}
      />
      <Typography variant="h6" style={{
        width: '100%', padding: 8,
        // border:'2px solid #ccc', 
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'contain',
        backgroundImage: preview ? `url(${preview})` : 'url(/image/placeholder.png)',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        backgroundPosition: 'center top'
      }}>
        {compressing ? <CircularProgress /> :
          <div style={{ opacity: 0.5 }}>
            Ide kattintson fotó készítéséhez vagy kiválasztásához,
          </div>}
      </Typography>
    </label>
  </div>
}

export default ({ close }) => {
  const { page, openDrawer } = useDrawer();
  const dispatch = useDispatch('msg');
  const dispatchPiheno = useDispatch('piheno');
  const potkocsi = useValue('potkocsi').frsz;
  const jlist = useValue('jarat');
  const aktivjarat = useValue('aktivjarat');
  const trancard = useValue('trancard');

  if (!page.send) page.send = {};
  const p = page.send.id || 'txt';
  const hivatkozas = page.send && page.send.hivatkozas;
  if (!page.send.jarat) {
    let jarat = aktivjarat;
    if (!jarat && jlist && jlist.length) {
      let kesz = jlist.filter(j => j.lezarva);
      if (kesz.length) {
        kesz.sort((a, b) => asc(a.kezdet, b.kezdet));
        jarat = kesz.pop().pos
      }
    }
    page.send.jarat = jarat;
  }

  const validate = ({ txt, tank, liter, km, card, huto, hutotele }) => {
    const errors = {};
    if (tank >= 0) {
      if (!liter) errors.liter = 'Mennyit tankolt?';
      if (!km) errors.km = 'Addja meg a km óra állását!';
      if (!card) errors.card = 'Mivel fizetett?';
      if (tank === 2) {
        if (!huto)errors.huto = 'Kötelező megadni az óraállást';
        if (hutotele !== 0 && hutotele !== 1) {
          errors.huto = 'Adja meg, hogy tele tankolta-e a hűtőt';
        }
      }
    } else if (liter || km) errors.tank = 'Kötelező megadni a tankolás jellegét!';
//    console.log("validate",errors);
    return errors;
  }

  const onSubmit = ({ txt, tank, liter, km, card, piheno, vankilenc, file, tipus, jarat, offset, sablon, opt1, opt2, huto, hutotele }) => {
    let rnev = '';
    let ertek = [];
    if (tank >= 0 && liter && km) {
      switch (tank) {
        case 0: txt = `Tele tankoltam ${liter}l`; break;
        case 1: txt = `${liter}l-t tankoltam`; break;
        case 2: if (hutotele) {
          txt = `Tele tankoltam a ${potkocsi || ''} hűtőt`;
        } else {
          txt = `${liter}l-t tankoltam a ${potkocsi || ''} hűtőbe`;
        }
          break;
        case 3: txt = `${liter}l AdBluet tankoltam`; break;
        default: break;
      }
      rnev = 'tankolas';
      txt += ` [kmóra: ${km}]`;
      const kartya = trancard.find(t => t.id === card);
      if(huto){
        huto = Number(huto.replace(',', '.'));
      }
      ertek = [`jelleg:${tank}`, `liter:${liter}`, `km:${km}`, `kartya:${kartya.id}`, `fizmod:${kartya.tipus}`, `jarat:${jarat || ''}`, `huto:${huto || ''}`, `hutotele:${hutotele || ''}`];
    }
    if (piheno) {
      let kezdet = stampFromMoment(moment().add(offset, 'm'));
      let vege = moment().add(piheno, 'h').add(offset, 'm');
      txt = `${piheno}${piheno === 45 ? '-ös' : '-es'} pihenőt megkezdtem. Vége: ${vege.format('dd HH:mm')}${vankilenc ? ', még van 9-esem' : ''}`;
      rnev = 'piheno';
      ertek = [`ido:${kezdet}\npiheno:${piheno}\nvankilenc:${vankilenc ? 1 : 0}`];
      dispatchPiheno('start', { kezdet, ora: piheno });
    }
    if (file) {
      if (file === 'compressing') return;
      if (!txt) {
        switch (tipus) {
          case 1: txt = 'CMR'; rnev = 'cmr'; break;
          case 2: txt = 'Menetlevél'; rnev = 'menetlevel'; break;
          case 3: txt = 'Plomba'; rnev = 'plomba'; break;
          default: txt = 'Küldök fotót'; rnev = 'upload'; break;
        }
      }
      if (jarat) txt += ` a ${jarat} járathoz`;
      ertek = [`tipus:${tipus || ''}`, `jarat:${jarat || ''}`]
    }
    if (sablon) {
      let st = sablon.text;
      if (sablon.opt1 && sablon.opt1.length) st = st.replace('$1', sablon.opt1[opt1]);
      if (sablon.opt2 && sablon.opt2.length) st = st.replace('$2', sablon.opt2[opt2]);
      ertek = [`sablon:${sablon.rnev}`, `opt1:${opt1}`, `opt2:${opt2}`, `txtlen:${txt.length}`];
      rnev = 'sablon';
      txt = txt ? st + '\n' + txt : st;
      sablon = sablon.rnev;
    }
    ertek = ertek.join('\n');

    if (!txt) return;

    if (hivatkozas) {
      dispatch('valasz', { msg: txt, hivatkozas, rnev, ertek, jarat, file, km, sablon });
    } else {
      dispatch('send', { msg: txt, rnev, ertek, jarat, file, km, sablon });
    }
    if (file) {
      close();
      setTimeout(openDrawer('send', { jarat, km, id: 'feltoltes' }), 10);
    } else {
      close();
    }
  }

  let initialValues = page.send || { km: '' };
  initialValues.offset = 0;
  const landscape = window.screen.orientation && window.screen.orientation.type.startsWith("landscape");
  let rows = 6;
  if (landscape) {
    rows = 2;
  }
  // console.log("orientation",window.screen.orientation);

  return <Formik initialValues={initialValues} validate={validate} enableReinitialize validateOnChange onSubmit={onSubmit}><Form>
    <div style={{
      height: '100vh'
    }}>
      <BottomNavigation
        value={p}
        onChange={(event, value) => { openDrawer('send', { ...page.send, id: value }, 'replace')(); }}
        showLabels={true}
      >
        <BottomNavigationAction icon={<Chat />} label={!landscape && "Üzenet"} value="txt" />
        <BottomNavigationAction icon={<LocalGasStation />} label={!landscape && "Tankolás"} value="tankolas" />
        <BottomNavigationAction icon={<AddAPhoto />} label={!landscape && "Felöltés"} value="feltoltes" />
        <BottomNavigationAction icon={<LocalParking />} label={!landscape && "Piheno"} value="piheno" />

      </BottomNavigation>
      <div style={{ padding: 8 }}>
        {p === 'txt' && <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <SablonField />
            <TextField name="txt" fullWidth autoFocus variant="filled" multiline rows={rows} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SablonSelector />
          </Grid>
        </Grid>}
        {p === 'feltoltes' && <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <JaratField />
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
            <UploadTipus />
          </Grid>
          <Grid item xs={12} style={{ flexGrow: 100 }}>
            <UploadField />
          </Grid>
        </Grid>
        }


        {p === 'piheno' && <Grid container spacing={1} style={{ textAlign: 'center' }}>
          <Grid item xs={12} sm={6}>
            <PihenoField />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <SwitchField name="vankilenc" label="van még 9 órásom" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <OffsetInput label='Pihenő kezdete' />
          </Grid>
        </Grid>
        }

        {p === 'tankolas' && <div style={{ padding: 16 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TankField />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField type="number" name="liter" label="Mennyiség" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KMOraInput inputProps={{}} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KartyaInput />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HutoField />
            </Grid>
          </Grid>
        </div>}

        <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', margin: 16 }}>
          <Button onClick={(e) => {
            close();
          }}>Mégsem</Button>

          <Button type="submit" color="primary" variant="contained" startIcon={<Send />} onClick={async (e) => {
          }}>{hivatkozas ? 'Válasz küldése' : 'Küldés'}</Button>
        </div>
      </div>
    </div >
  </Form></Formik >
}