import React, { useEffect, useRef } from 'react';
import { List, ListItem, Button, makeStyles, Grid, Link, LinearProgress } from '@material-ui/core';
import { format } from '../../utils.mjs';
import { useValue, useDispatch, syncMsg } from '../data';
import { useDrawer } from '../hooks';
import { Link as LinkIcon, Refresh, Phone, Directions, Map } from '@material-ui/icons';
import { ErrorBoundary } from './ErrorBoundary';
import config from '../config.json'



const useStyles = makeStyles(theme => ({
  uj: {
    margin: '20px 40px 0px 20px',
    background: `${config.color.primary}CC`,
    padding: 20,
    position: 'relative',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    whiteSpace: 'pre-line',

    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderRight: `10px solid ${config.color.primary}CC`,
      borderLeft: '10px solid transparent',
      borderTop: `10px solid ${config.color.primary}CC`,
      borderBottom: '10px solid transparent',
      left: -20,
      top: 0
    }
  },
  incoming: {
    margin: '20px 40px 0px 20px',
    background: `${config.color.primary}66`,
    padding: 12,
    position: 'relative',
    borderRadius: 8,
    borderTopLeftRadius: 0,
    whiteSpace: 'pre-line',

    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderRight: `10px solid ${config.color.primary}66`,
      borderLeft: '10px solid transparent',
      borderTop: `10px solid ${config.color.primary}66`,
      borderBottom: '10px solid transparent',
      left: -20,
      top: 0
    }
  },
  incomingstamp: {
    textSize: 10,
    color: '#a5a5a5',
    textAlign: 'left',
    marginLeft: 20,
    marginBottom: 12,
  },


  outgoing: {
    margin: '20px 20px 0px 40px',
    marginBottom: 0,
    background: '#444',
    padding: 12,
    position: 'relative',
    textAlign: 'right',
    borderRadius: 8,
    borderTopRightRadius: 0,
    whiteSpace: 'pre-line',
    '&::before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      borderLeft: '10px solid #444',
      borderRight: '10px solid transparent',
      borderTop: '10px solid #444',
      borderBottom: '10px solid transparent',
      right: -20,
      top: 0
    }
  },
  outgoingstamp: {
    textSize: 10,
    color: '#a5a5a5',
    textAlign: 'right',
    marginRight: 20,
    marginBottom: 12,
  },
  action: {
    textAlign: 'center',
    padding: 12,
    margin: -20,
    marginTop: 10,
    borderTop: '1px solid #2222',
    background: '#22222222',
  },
  msg: {
    width: '100%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

function Incoming({ sorszam, msg, sablon, nysofor, upload, created, cuser, ertek, rnev, jarat,sendtour }) {
  const classes = useStyles();
  const dispatch = useDispatch('msg');
  const { openDrawer } = useDrawer();
  let tel = null, gps = null, openjarat = null;
  if(ertek) ertek = ertek.split('\n');
  if (rnev === 'atakasztas' && ertek) {
    tel = <Button startIcon={<Phone/>} variant="outlined" href={"tel:" + ertek[0]}>Hívás</Button>;
    gps = <> 
      <Button startIcon={<Map/>} variant="outlined" href={`https://google.com/maps/search/?api=1&query=${ertek[1]}&z=15`} target='_google_maps'>Térkép</Button>
      <Button startIcon={<Directions />} variant="outlined" href={`https://google.com/maps/dir/?api=1&destination=${ertek[1]}`} target='_google_maps'>Útv.</Button>
    </>;
    //openjarat = <Button variant="outlined" onClick={openDrawer('jarat', {jarat})}>Járat</Button>
  }
  let reply = [];
  if (ertek) {
    reply = ertek.filter(s => s.substr(0, 6) === 'reply_').map((s) => {
      const nr = s.substr(6, 1);
      const txt = s.substr(8);
      return <Grid item key={nr} xs={6} ><Button key={s} variant="contained" onClick={() => {
        dispatch('valasz', { msg: txt, sablonreply: sablon, ertek: `reply:${nr}`, hivatkozas: sorszam })
      }}>{txt}</Button></Grid>
    })
  }

  if (reply.length === 0) reply.push(<Grid xs={6} item key='Ok'><Button key='OK' variant="contained" onClick={() => {
    dispatch('valasz', { msg: `OK: ${msg.substr(0, 30)} ${msg.length > 30 ? '...' : ''}`, hivatkozas: sorszam })
  }}>OK</Button></Grid>)
  if (reply.length === 1) {
    reply.unshift(<Grid item xs={6} key="valasz"><Button variant="contained" onClick={() => {
//      console.log("Válaszol");
      openDrawer('send', { id: 'txt', hivatkozas: sorszam })();
    }}>Válaszol</Button></Grid>)
  }

  const onClick = (ev) => {
//    console.log("OnClick",jarat);
    if (jarat && rnev!=='lemondva')  openDrawer('jarat', { jarat })();
  }

  if(sendtour  && config.nojaratmsg && rnev!=='lemondva'){
    msg = `A ${jarat} járatszámú feladat megérkezett. További infókhoz kattintson ide, vagy nézze meg a fuvarok között.`
  }

  return <><div className={nysofor ? classes.incoming : classes.uj}>
    {upload && <Link href={upload} target="_blank"><div style={{ textAlign: 'right' }}><LinkIcon /></div></Link>}
    <div className={classes.msg} onClick={onClick}>
      {msg}
      <div style={{ textAlign: 'center',marginTop:8 }}>
        {tel}{gps}{openjarat}
      </div>
    </div>
    {!nysofor && <div className={classes.action}>
      <Grid container spacing={1}>
        {reply}
      </Grid>
    </div>}
  </div>
    <div className={classes.incomingstamp}>{cuser} {format.fromNow(created)}</div>
  </>
}


function Outgoing({ id, msg, upload, stamp, pending }) {
  const classes = useStyles();
  return <><div className={classes.outgoing}>
    {upload && <Link href={upload} target="_blank"><div style={{ textAlign: 'left' }}><LinkIcon /></div></Link>}
    {pending ? <div className={classes.pending}>
      <div className={classes.msg}>{msg}</div>
      <LinearProgress />
    </div> :
      <div className={classes.msg}>{msg}</div>
    }
  </div>
    <div className={classes.outgoingstamp}>{format.fromNow(stamp)}</div>
  </>
}


export function MessageList(props) {
  let list = useValue('msg');
  const end = useRef();
  const view = useRef();
  useEffect(() => {
    if (end.current && view.current) {
      view.current.parentElement.scrollTop = end.current.offsetTop;
    }
  }, [list]);

  if (!list) return null;
  if (list && props.filter) {
    list = list.filter(props.filter);
  }

  return <List ref={view} style={props.style || { width: '100vw' }}>
    {list.map((msg, i) => msg.irany === 1 ? <Outgoing key={msg.sorszam || i + 10000000} {...msg} /> : <Incoming key={msg.sorszam} {...msg} />)}
    <ListItem ref={end} style={{ height: 60 }}>
      <Button onClick={syncMsg}><Refresh /></Button>
    </ListItem>
  </List>
}

export function Inbox(props) {
  let {closeDrawer} = useDrawer();
  let list = useValue('msg');
  if (list) list = list.filter(m => !m.nysofor);

    useEffect(()=>{
      if(!list || !list.length){
        let t = setTimeout(closeDrawer,1000); 
        return ()=>clearTimeout(t);
      }
    },[list]);

  if (!list || !list.length) {
    return <div style={{ width: '50vw', padding: 30 }}>Nincs új üzenet</div>
  }
  return <ErrorBoundary component="MessageList"><List style={{ width: '100vw' }}>
    {list.map((msg) => <Incoming key={msg.sorszam} {...msg} />)}
  </List></ErrorBoundary>
}


