import React from 'react';
import { Grid } from '@material-ui/core';
import { Speed, PowerSettingsNew, PhonelinkSetup, Build, AssignmentTurnedIn, FolderShared, Star, Info, Visibility, HotTub} from '@material-ui/icons';
import { useDispatch, useValue} from '../data';
import { useDialog } from '../rf/Dialog';
import { useHistory } from 'react-router-dom';
import { PotIcon } from '../vezerlopult/SoforStatus';
import { ErrorBoundary } from './ErrorBoundary';
import { Eurowag } from './Eurowag';
import config from '../config.json'
import { useLogout } from '../dlg/ValtasDlg';

function MenuItem({ onClick, icon, label }) {
  return <Grid item xs={6} ms={4} onClick={onClick} style={{ textAlign: 'center' }}><div>{icon}</div>{label}</Grid>
}

export default () => {
  const history = useHistory();
  const { setDialog } = useDialog();
  const logout = useLogout();
  const potkocsi = useValue('potkocsi').frsz;
  const { kocsi } = useValue('config');
  const sos = useDispatch('sos');


  return <ErrorBoundary component="EgyebMenu" top={10}>
    <Grid container spacing={4} style={{ padding: 16 }}>

    {config.eurowag && config.eurowag.timeout && <Eurowag/>}

    <MenuItem onClick={() => setDialog({ id: 'resetkmora' })} icon={<Speed />} label="Kmóra állás" />
    {potkocsi ?
      <MenuItem onClick={() => setDialog({ id: 'leakasztas' })} icon={<PotIcon />} label={`${potkocsi} leakasztása`} /> :
      <MenuItem onClick={() => setDialog({ id: 'felakasztas' })} icon={<PotIcon />} label={'Pótkocsi felakasztás'} />
    }
    <MenuItem onClick={() => setDialog({ id: 'karjelentes', frsz: kocsi })} icon={<Build />} label={'Vontató hiba'} />
    {potkocsi && <MenuItem onClick={() => setDialog({ id: 'karjelentes', frsz: potkocsi })} icon={<Build />} label={'Pótkocsi hiba'} />}
    <MenuItem onClick={() => setDialog({ id: 'urlap'})} icon={<AssignmentTurnedIn />} label={'Jegyzőkönyv'} />
    {config && config.documents &&  <MenuItem onClick={() => setDialog({ id: 'doksik'})} icon={<FolderShared />} label={'Dokumentumok'} />}
    {config && config.ertekeles &&  <MenuItem onClick={() => setDialog({ id: 'ertekeles'})} icon={<Star />} label={'Értékelés'} />}
    {config.pontinfo && <MenuItem onClick={() => {
        setDialog({ id: 'pontinfo', jarat: '', tetel: '' });
      }}
      icon={<Info/>} label="Rakodó infó" />}

    {config && config.tapa && <MenuItem onClick={() => setDialog({id:'tapa'})} icon={<Visibility />} label="T A P A" />}
    {config && config.szabadsagigeny && <MenuItem onClick={() => setDialog({id:'szabadsagigeny'})} icon={<HotTub />} label="Szabadság" />}


    <MenuItem onClick={() => history.push('/status')} icon={<PhonelinkSetup />} label="Eszköz státusz" />
    <MenuItem onClick={logout} icon={<PowerSettingsNew />} label="Kijelentkezés" />
  </Grid>
  <div style={{position:'fixed', left:0,bottom:32,height:100,right:0,textAlign:'center'}}>
    <div style={{margin:'32px 100px',borderRadius:32, padding:24,background:'#900',border:'1px solid #fff', display:'flex',justifyContent:'center',alignContent:'center',alignItems:'center',height:40, fontSize: '1.5rem'}}
      onClick={()=>sos('countdown')}>
      S. O. S.
    </div>
  </div>

  </ErrorBoundary>
}