import React, { useEffect, useCallback, useState } from 'react';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { useDrawer } from '../hooks';
import { useDispatch, useValue } from '../data';
import { TextField } from '../rf/input/Text';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { NativeSelect } from '../rf/input/Select';
import { JaratField, UploadField } from './UzenetKuldes';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { stampFromMoment, stampToMoment } from '../../utils.mjs';
import { GpsFixed } from '@material-ui/icons';


function TetelField({ jarat }) {

  let { values } = useFormikContext();
  let options = [];
  if (jarat && values.jarat) {
    let f = jarat.find(j => j.pos === values.jarat);
    options = f.tetel.filter(t => t.sajat && (t.jelleg === 1 || t.jelleg === 4)).map(t => ({ value: t.sorszam, label: `${t.pdisplay||''} ${t.orszag} ${t.irsz}` }));
  }
  return <Field fullWidth component={NativeSelect} name="tetel" label="Rakodópont" options={options} />
}

export default ({ close, open }) => {
  const dispatch = useDispatch('msg');
  let jaratList = useValue('jarat');
  const pos = useValue('pos');
  const dispatchPos = useDispatch('pos');
  const [validpos, setValidPos] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(p => {
      const { latitude, longitude, accuracy, speed, heading } = p.coords;
      let timestamp = moment(new Date(p.timestamp)).format('YYYY-MM-DD HH:mm:ss');
      let domts = p.timestamp;
      let val = { coords: { latitude, longitude, accuracy, speed, heading }, timestamp, domts };
      dispatchPos('update', val);
    }, error => {
      console.log(error);
    },
      { maximumAge: 0, enableHighAccuracy: true })
  }, [dispatchPos]);

  useEffect(() => {
    if (!pos || !pos.pos) {
      setValidPos(false);
      return;
    }
    let diff = moment().diff(stampToMoment(pos.pos.timestamp), 's');
    if (diff > 30) {
      setValidPos(false);
      return;
    } else {
      setValidPos(true);
      return;
    }

  }, [pos, setValidPos]);



  const validate = ({ txt, jarat, tetel }) => {
    const errors = {};
    if (!txt) errors.txt = 'Ide írja be a rakodóval kapcsolatos információt';
    if (!jarat) errors.jarat = 'Melyik járatról van szó?';
    if (!tetel) errors.tetel = 'Melyik rakodópontról van szó?';
    return errors;
  }

  const onSubmit = ({ txt, jarat, tetel, file }) => {
    console.log("submit pontinfo", jarat, tetel, file);
    if (file === 'compressing') return;
    let rnev = 'pontinfo';
    let j = jaratList.find(j => j.pos == jarat);
    console.log("submit pontinfo", j);
    if (!j) return;
    let t = j.sajattetel.find(t => t.sorszam == tetel);
    console.log("submit pontinfo", t);
    if (!t) return;
    let ertek = `jarat:${jarat}\ntetel:${tetel}\npont:${t.pont || ''}\nmemo:${txt}\njelleg:${t.jelleg}`;
    let msg = `Rakodó infók: ${t.orszag} ${t.varos}, ${t.prnev}:\n${txt}`;
    dispatch('send', { msg, rnev, ertek, jarat, file });
    close();
  }

  let initialValues = open;

  const landscape = window.screen.orientation && window.screen.orientation.type.startsWith("landscape");
  let rows = 6;
  if (landscape) {
    rows = 2;
  }

  return <Formik initialValues={initialValues} validate={validate} enableReinitialize validateOnChange onSubmit={onSubmit}><Form>
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <JaratField />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TetelField jarat={jaratList} />
          </Grid>
          <Grid item xs={12}>
            <TextField name="txt" fullWidth autoFocus variant="filled" multiline rows={rows} placeholder="Hasznos információk, figyelmeztetések" />
          </Grid>
          <Grid item xs={12} style={{ flexGrow: 100 }}>
            <UploadField />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} >Mégsem</Button>
        <div style={{ flex: '1 0 0' }} />
        <Button
          endIcon={validpos ? <GpsFixed /> : <CircularProgress color="inherit" size={20} />}
          type="submit"
          variant="contained"
          color="primary">
          Infók beküldése
        </Button>
      </DialogActions>    </div >
  </Form>
  </Formik >
}